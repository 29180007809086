import React, { useMemo, useState } from "react";
import useHttp from "../../../hooks/use-http";
import { Card, Modal, Row, Switch } from "antd";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { convertUTCToLocal, PERMISSION } from "../../../util/functions";
import { useNavigate } from "react-router-dom";
import { deviceUserList } from "../../../util/exportData";

function UserDevice({ admindetails, permissionId }) {
  const [view, setView] = useState(null);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const api = useHttp();
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];

  const deviceUpdate = (checked) => {
    api.sendRequest(
      CONSTANTS?.API.Device_Management.update,
      () => {
        setView((pre) => ({ ...pre, isBlocked: checked }));
        setReload((prev) => !prev);
      },
      { isBlocked: checked, deviceId: view?.deviceId },
      `Device ${checked ? "Block" : "Unblock"} Successfully`
    );
  };
  const memoizedExtraQuery = useMemo(() => {
    return {
      deviceId: view?.deviceId,
    };
  }, [view?.deviceId]);

  return (
    <div>
      <Card className="my-5">
        <Heading>Device Management</Heading>
        <Row>
          <CRUDComponent
            reload={reload}
            title="User"
            accessPermission={accessPermission}
            GET={{
              API: CONSTANTS.API.Device_Management.getAll,
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.no,
                    view: {
                      id: data?.id,
                      onClick: (id) => {
                        setView({
                          deviceId: data?.deviceId,
                          isBlocked: data?.isBlocked,
                        });
                      },
                    },
                    blocked: {
                      checked: !data?.isBlocked,
                      id: data?.id,
                      isDisable: PERMISSION[accessPermission] === "READ",
                      onClick: (id, val) => {
                        API.sendRequest(
                          CONSTANTS?.API.Device_Management.update,
                          () => {
                            Setrefresh((prev) => !prev);
                          },
                          { isBlocked: val, deviceId: data?.deviceId },
                          `Device ${val ? "Block" : "Unblock"} Successfully`
                        );
                      },
                    },
                  };
                });
              },
              column: CONSTANTS.TABLE.USER_DEVICE,
            }}
            //   isSearch
          />
        </Row>
      </Card>
      <Modal
        title={
          <div
            className="flex justify-between"
            style={{ width: "calc(100% - 35px)" }}
          >
            <p>User List</p>
            <div className="flex">
              <p className="me-2">Block: </p>
              <Switch
                size={"default"}
                onChange={(checked) => deviceUpdate(checked)}
                checked={view?.isBlocked}
                disabled={PERMISSION[accessPermission] === "READ"}
              />
            </div>
          </div>
        }
        centered
        open={view}
        width={1600}
        footer={false}
        onCancel={() => setView(null)}
      >
        <div>
          <CRUDComponent
            title="User"
            accessPermission={accessPermission}
            showPagination={false}
            exportData={deviceUserList}
            GET={{
              API: CONSTANTS.API.user.getAll,
              extraQuery: memoizedExtraQuery,
              DataModifier: (res, API, Setrefresh) => {
                console.log(res);
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.id,
                    view: {
                      id: data?.id,
                      onClick: (id) => {
                        navigate(`/app/user/${id}`);
                      },
                    },
                    isBlocked: {
                      checked: !data?.isBlocked,
                      id: data?.id,
                      isDisable: PERMISSION[accessPermission] === "READ",
                      onClick: (id, val) => {
                        const UpdateAPIEnd = {
                          ...CONSTANTS?.API.user.update,
                        };
                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                        API.sendRequest(
                          UpdateAPIEnd,
                          () => {
                            Setrefresh((prev) => !prev);
                          },
                          { isBlocked: val },
                          `User ${val ? "Blocked" : "Unblock"} Successfully`
                        );
                      },
                    },
                    dob: convertUTCToLocal(data?.DOB, "DD/MM/YYYY"),
                    userImage: { image: data?.image, name: data?.name },
                  };
                });
              },
              column: CONSTANTS.TABLE.USERLIST_DEVICE,
            }}
            isSearch
          />
        </div>
      </Modal>
    </div>
  );
}

export default UserDevice;
